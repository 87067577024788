import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import Layout from "../components/layout"

import { FaChartLine, FaUser, FaTabletAlt, FaCheck } from "react-icons/fa"
import Faq from "../components/faq";


const data = {
  rows: [
    {
      title: "Can I cancel",
      content: `Yes - of course. If you are not satisfied, you can cancel at anytime for any reason. The one-time setup fee is non-refundable. The monthly charge will cover the rest of the current month, and then you will not be charged anymore.`,
    },
    {
      title: "Can I keep my domain name if I cancel",
      content:
        "Absolutely. If you want to transfer for it, just let us know. We will work with the IT contact to get it done.",
    },
    {
      title: "What does NWR stand for",
      content: `It's based on the Latin proverb: If there <strong>N</strong>o <strong>W</strong>ind, <strong>R</strong>ow</p>
        <p>
          In other words, we make our own destinty. Don't wait for your website to
          just happen, contact us today! Let us make it happen.
        </p>
        `,
    },
  ],
}

const FAQPage = () => (
  <Layout>
    <div className="title is-2">Frequently Asked Questions</div>
    <Faq data={data} />
  </Layout>
)

export default FAQPage
